import React, { useEffect, useState } from 'react';
import "./GamesGrid.css";
import { Col, Row, Spinner, Pagination} from 'react-bootstrap';
import { generateDate } from '../../helpers/utils';

const GamesGrid = (props) => {
    const { slides, buttonFunc, isMobile } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const gamesPerPage = 5;


    function handlePlayGameClick(gameId, index){
        console.log(gameId, index)
        buttonFunc(gameId, index);
    }

    function handleJoinLeague(teamId){
        props.getNextDivisionTeams(teamId);
    }

    const indexOfLastGame = currentPage * gamesPerPage;
    const indexOfFirstGame = indexOfLastGame - gamesPerPage;
    const currentGames = slides ? slides.slice(indexOfFirstGame, indexOfLastGame) : [];

    const pageNumbers = [];
    if (slides) {
        for (let i = 1; i <= Math.ceil(slides.length / gamesPerPage); i++) {
            pageNumbers.push(i);
        }
    }

    const renderPageNumbers = pageNumbers.map(number => {
        return (
            <Pagination.Item key={number} active={number === currentPage} onClick={() => setCurrentPage(number)}>
                {number}
            </Pagination.Item>
        );
    });

    const cardHeight = isMobile ? "175px" : "175px";
    const minCardWidth = isMobile ? "300px" : "600px";

    // console.log(props.leagueId)
    return (
        <div>
        {slides !== undefined ? (
            <>
            {currentGames.length == 0 ? (
                <>
                <Row 
                    className="box-shadow-simple justify-content-center" 
                    style={{paddingTop:"2%", backgroundColor: '#fff', width: "80%", minWidth: minCardWidth, minHeight: cardHeight, margin: "auto", marginBottom: "2%", borderRadius: "10px"}}
                >
                    <Col md="auto">
                    <Row className='justify-content-center' style={{fontStyle: 'italic', paddingBottom: "5%"}}>
                        No Games Available to Play
                    </Row>
                    {props.leagueId == 0 && (
                    <Row className='justify-content-center'>
                        <Col md={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <button 
                                className="gradient-button" 
                                onClick={() => handleJoinLeague(props.ownedTeamId)}
                            >
                                <div className="sub-title-mobile">
                                    Join a Division!
                                </div>
                            </button>
                        </Col>
                    </Row>
                    )}
                    </Col>
                </Row>
                </>
            ):(
                <>
                {currentGames.map((slide, index) => (
                    <>
                    <Row 
                        key={index} 
                        className="box-shadow-simple " 
                        style={{paddingTop:"2%", backgroundColor: '#fff', width: "80%", minWidth: minCardWidth, margin: "auto", marginBottom: "2%", borderRadius: "10px"}}
                    >
                        <Col md={3} style={{display: 'flex', flexDirection: 'column-reverse', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <img src={slide.imageUrlHome} height={"125px"}/>
                        </Col>
                        <Col style={{paddingBottom:"2%"}}>
                            <Row className='justify-content-center'>
                                <Col md="auto" style={{display: "flex", justifyContent: "flex-center"}}>
                                    <p style={{fontSize: "0.9rem"}}>{generateDate(slide.gameStart)}</p>
                                </Col>
                            </Row>
                            <Row className='justify-content-center'>
                                <Col md={5}  style={{display: "flex", justifyContent: "flex-end", alignItems: 'center'}}>
                                    <p>{slide.teamNameHome}</p>
                                </Col>
                                <Col md={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <p>vs</p>
                                </Col>
                                <Col md={5} style={{display: "flex", justifyContent: "flex-start", alignItems: 'center'}}>
                                    <p>{slide.teamNameAway}</p>
                                </Col>
                                
                            </Row>
                            <Row className='justify-content-center'>
                                <button 
                                    className="button-1b" 
                                    style={{width: "150px"}} 
                                    onClick={() => handlePlayGameClick(slide.gameId,Number(index))}
                                >
                                    {slide.buttonText}
                                </button>
                            </Row>
                        </Col>
                        <Col md={3} style={{display: 'flex', flexDirection: 'column-reverse', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <img src={slide.imageUrlAway} height={"125px"}/>
                        </Col>
                    </Row>
                    </>
                ))}
                <Row className='justify-content-center'>
                    <Col md="auto">
                        <Pagination className="custom-pagination">
                            {renderPageNumbers}
                        </Pagination>
                    </Col>
                </Row>
                </>
            )}
            </>
        ):(
        <Row 
            className="box-shadow-simple justify-content-center" 
            style={{paddingTop:"2%", backgroundColor: '#fff', width: "80%", minWidth: minCardWidth, minHeight: cardHeight, margin: "auto", marginBottom: "2%", borderRadius: "10px"}}
        >
            <div style={{fontStyle: 'italic'}}>
                Loading Available Games..
               <Spinner animation="border" variant="dark" style={{borderWidth: '4px', height: '1rem', width: '1rem'}} />
            </div>
        </Row>
        )}
        </div>
    );
};

export default GamesGrid;
