import axios from 'axios';

axios.defaults.baseURL = 'https://api-v1.chainballers.xyz'; // 'http://localhost:3001';   // https://api-v1.chainballers.xyz'; 

export async function getData(){
    axios.get('/api/data')
    .then((response) => {
    console.log(response.data)
    })
    .catch((error) => {
    console.error('Error fetching data:', error);
    });
}


export async function getNewTeamCost(){
    let result = axios.get('/api/v1/cb/newTeamCost')
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.error('Error fetching data:', error);
        return 0;
    });
    return result;
}

export async function getCouponCodes(){
    let result = axios.get('/api/v1/cb/getCouponCodes')
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.error('Error fetching data:', error);
        return 0;
    });
    return result;
}

export async function sendCouponCode(account,signedMessage){
    let data ={};
    data.signature = signedMessage;
    data.userAddress = account;
    const postData = {
        title: 'Coupon Code',
        data: data,
      };
    axios.post('/api/v1/cb/postCoupon', postData)
    .then((response) => {
      console.log('Post successful:', response.data);
      // Handle the response as needed
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle errors
    });
}

export async function isCouponSponsored(account,signedMessage){
    let data ={};
    data.signature = signedMessage;
    data.userAddress = account;
    let result = false;
    const postData = {
        title: 'Coupon data',
        data: data,
      };
    await axios.post('/api/v1/cb/isCouponSponsored', postData)
    .then((response) => {
    //   console.log('isCouponSponsored:', response.data);
      // Handle the response as needed
      result = response.data.result;
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle errors
    });
    return result;
}

export async function createTeamFromCoupon(account,signedMessage){
    let data ={};
    data.signature = signedMessage;
    data.userAddress = account;
    const postData = {
        title: 'Coupon Code',
        data: data,
      };
    axios.post('/api/v1/cb/createTeam', postData)
    .then((response) => {
      console.log('Post successful:', response.data);
      // Handle the response as needed
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle errors
    });
}

export async function getGameCommentary(gameResultRaw, leagueRaw){
  // console.log(gameResultRaw, leagueRaw)
  let text = "Create a game commentary of 10 steps. Each step starts with Step <stepNumber>. Brief intro to today's game, then each step is a scoring chance, or other game event. Each step should be a sentence or two. Be entertaining and creative.";
  let gameResult = gameResultRaw.teamNameHome.concat(" ", gameResultRaw[3], " - ", gameResultRaw[4], " ", gameResultRaw.teamNameAway);
  let league = leagueRaw.map(team => {
    return {
        rank: team[0],
        team_id: team[1],
        team_name: team[2],
        points: team[3],
        goals: team[4]
    };
  });
  const stringLeague = league.map(team => {
    return `Rank: ${team.rank}, Team ID: ${team.team_id}, Team Name: ${team.team_name}, Points: ${team.points}, Goals: ${team.goals}`;
  }).join('\n');
  // console.log(gameResult, stringLeague)
  let context = 
  `Final result: ${gameResult}, 
  Game league rank, pre-game: ${stringLeague}}`
  let data ={}; let result = {};
  data.text = text;
  data.context = context;
  const postData = {
      title: 'Asking anything',
      data: data,
    };
  // console.log(data)
  await axios.post('/api/v1/openai/ask', postData)
  .then((response) => {
          // console.log("FE:", response);  
          result = response.data;
  })
  .catch((error) => {
        console.error('Error:', error);
        return null;
        // Handle errors
  });

  result = splitGameSummary(result)
  return result;
}


function splitGameSummary(text) {
  // Split the text into parts based on the "Step" keyword
  const parts = text.split(/Step \d+: /);

  // Remove the first empty element if it exists
  if (parts[0] === '') {
      parts.shift();
  }

  // Prepend "Step" with the step number to each part
  return parts.map((part, index) => `${part.trim()}`);
}

export async function speakGameCommentary(context, script){
  let data ={}; let result = {};
  data.context = context;
  data.script = script;
  const postData = {
      title: 'Asking anything',
      data: data,
    };
  await axios.post('/api/v1/openai/speak', postData)
  .then((response) => {
          console.log("FE:", response);  
          result = response.data;
  })
  .catch((error) => {
        console.error('Error:', error);
        return null;
        // Handle errors
  });

  return result;
}

export async function addTeam(owner, chainId){
  if (owner !== undefined && chainId !== undefined){
    let data ={}; let result = {};
    data.owner = owner;
    data.chainId = chainId;
    const postData = {
        title: 'Add team to db',
        data: data,
      };
      try{
      await axios.post('/api/v1/cb/addTeam', postData)
      .then((response) => {
              // console.log("FE:", response);  
              result = response.data;
      })
    }
    catch (error) {
      // console.error('Error:', error);
      return null;
    }
    return result;
  }
}

export async function updateTeam(owner, chainId){
  if (owner !== undefined && chainId !== undefined){
    let data ={}; let result = {};
    data.owner = owner;
    data.chainId = chainId;
    const postData = {
        title: 'Update team in db',
        data: data,
      };
    await axios.post(`/api/v1/cb/updateTeam/${owner}`, postData)
    .then((response) => {
            // console.log("FE:", response);  
            result = response.data;
    })
    .catch((error) => {
          console.error('Error:', error);
          return null;
          // Handle errors
    });
    return result;
  }
}

export async function addLeagueTeams(leagueId, chainId){
  let data ={}; let result = {};
    data.leagueId = leagueId;
    data.chainId = chainId;
    const postData = {
        title: 'Add remaining league teams to db',
        data: data,
      };
    await axios.post('/api/v1/cb/addLeagueTeams', postData)
    .then((response) => {
            // console.log("FE:", response);  
            result = response.data;
    })
    .catch((error) => {
          console.error('Error:', error);
          return null;
          // Handle errors
    });
    return result;
}

export async function updateAllLeagueTeams(leagueId, chainId){
  let data ={}; let result = {};
    data.leagueId = leagueId;
    data.chainId = chainId;
    const postData = {
        title: 'Update league teams to db',
        data: data,
      };
    try{
      await axios.post(`/api/v1/cb/updateAllTeams/${chainId}/${leagueId}`, postData)
      .then((response) => {
              // console.log("FE:", response);  
              result = response.data;
      })
      .catch((error) => {
            console.error('Error:', error);
            return null;
      });
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
    return result;
}

export async function addLeague(leagueId, chainId){
  if (leagueId !== undefined && chainId !== undefined){
    let data ={}; let result = {};
    data.leagueId = leagueId;
    data.chainId = chainId;
    const postData = {
        title: 'Add league to db',
        data: data,
      };
    await axios.post('/api/v1/cb/addLeague', postData)
    .then((response) => {
            console.log("FE:", response);  
            result = response.data;
    })
    .catch((error) => {
          console.error('Error:', error);
          return null;
          // Handle errors
    });
    return result;
  }
}

export async function addBaller(ballerId, chainId){
  if (ballerId !== undefined && chainId !== undefined){

    let data ={}; let result = {};
    data.ballerId = ballerId;
    data.chainId = chainId;
    const postData = {
        title: 'Add baller to db',
        data: data,
      };
    await axios.post(`/api/v1/cb/addBaller/${ballerId}`, postData)
    .then((response) => {
            // console.log("FE:", response);  
            result = response.data;
    })
    .catch((error) => {
          console.error('Error:', error);
          return null;
          // Handle errors
    });
    return result;
  }
}

export async function getTeamByOwner(owner){
  if (owner !== undefined){
    let result = {};
    await axios.get(`/api/v1/cb/getTeamByOwner/${owner}`)
    .then((response) => {
            // console.log("FE:", response);  
            result = response.data;
    })
    .catch((error) => {
          console.error('Error:', error);
          return null;
          // Handle errors
    });
    return result;
  }
}


export async function getLeagueData(leagueId, chainId) {
  // console.log("getting league data", leagueId);
  let data ={}; let result = {};
  data.leagueId = leagueId;
  data.chainId = chainId;
  const postData = {
      title: 'Get league data',
      data: data,
    };
  await axios.get(`/api/v1/cb/getLeagueTeams/${chainId}/${leagueId}`)
  .then((response) => {
          // console.log("FE:", response);  
          result = response.data;
  })
  .catch((error) => {
        console.error('Error:', error);
        return null;
        // Handle errors
  });
  return result;
}

export async function getBallerData(ballerId, chainId) {
  // console.log("getting baller data", leagueId);
  let data ={}; let result = {};
  data.chainId = chainId;
  const postData = {
      title: 'Get baller data',
      data: data,
    };
  await axios.get(`/api/v1/cb/getBallerData/${ballerId}`, postData)
  .then((response) => {
          // console.log("FE:", response);  
          result = response.data;
  })
  .catch((error) => {
        console.error('Error:', error);
        return null;
        // Handle errors
  });
  return result;
}

export async function getBallersByTeam(teamId, chainId) {
  let data ={}; let result = {};
  data.teamId = teamId;
  data.chainId = chainId;
  await axios.get(`/api/v1/cb/getBallersByTeam/${chainId}/${teamId}`)
  .then((response) => {
          // console.log("FE:", response);  
          result = response.data;
  })
  .catch((error) => {
        console.error('Error:', error);
        return null;
        // Handle errors
  });
  return result;
}

export async function getAvailableGames(leagueId, chainId) {
  // console.log("getting available games", leagueId);
  let data ={}; let result = {};
  data.leagueId = leagueId;
  data.chainId = chainId;
  const postData = {
      title: 'Get available games',
      data: data,
    };
  await axios.get(`/api/v1/cb/getAvailableGames/${leagueId}`, postData)
  .then((response) => {
          // console.log("FE:", response);  
          result = response.data;
  })
  .catch((error) => {
        console.error('Error:', error);
        return null;
        // Handle errors
  });
  return result;
}

export async function getLeagueCalendar(leagueId, chainId) {
  // console.log("getting league calendar", leagueId);
  let data ={}; let result = {};
  data.leagueId = leagueId;
  data.chainId = chainId;
  const postData = {
      title: 'Get league calendar',
      data: data,
    };
  await axios.get(`/api/v1/cb/getLeagueCalendar/${chainId}/${leagueId}`, postData)
  .then((response) => {
          // console.log("FE:", response);  
          result = response.data;
  })
  .catch((error) => {
        console.error('Error:', error);
        return null;
        // Handle errors
  });
  return result;
}


export async function addGame(gameId, chainId) {
  // console.log("adding game", gameId);
  let data ={}; let result = {};
  data.gameId = gameId;
  data.chainId = chainId;
  const postData = {
      title: 'Add game',
      data: data,
    };
  await axios.post(`/api/v1/cb/addGame/${gameId}`, postData)
  .then((response) => {
          // console.log("FE:", response);  
          result = response.data;
  })
  .catch((error) => {
        console.error('Error:', error);
        return null;
        // Handle errors
  });
  return result;
}

export async function updateGameScore(gameId, chainId) {
  // console.log("updating game score", gameId);
  let data ={}; let result = {};
  data.gameId = gameId;
  data.chainId = chainId;
  const postData = {
      title: 'Update game score',
      data: data,
    };
  await axios.post(`/api/v1/cb/updateGameScore/${gameId}`, postData)
  .then((response) => {
          // console.log("FE:", response);  
          result = response.data;
  })
  .catch((error) => {
        console.error('Error:', error);
        return null;
        // Handle errors
  });
  return result;
}

export async function deleteBaller(ballerId) {
  try {
      const response = await axios.delete(`/api/v1/cb/deleteBaller/${ballerId}`);
      console.log(`Baller with ID ${ballerId} deleted successfully:`, response.data);
      return response.data;
  } catch (error) {
      console.error(`Error deleting baller with ID ${ballerId}:`, error.response ? error.response.data : error.message);
      throw error;
  }
}

export async function getTeamFormation(chainId, teamId) {
  // console.log("getting team formation", teamId);
  try {
      const response = await axios.get(`/api/v1/cb/getTeamFormation/${chainId}/${teamId}`);
      return response.data;
  } catch (error) {
      console.error(`Error loading formation for team with ID ${teamId}:`, error.response ? error.response.data : error.message);
      throw error;
  }
}


export async function getTrainingStatus(chainId, teamId) {
  // console.log("getting team formation", teamId);
  try {
      const response = await axios.get(`/api/v1/cb/getTrainingStatus/${chainId}/${teamId}`);
      return response.data;
  } catch (error) {
      console.error(`Error loading training status for team with ID ${teamId}:`, error.response ? error.response.data : error.message);
      throw error;
  }
}

export async function getNextDivisionTeams(chainId) {
  // console.log("getting next division teams");
  try {
      const response = await axios.get(`/api/v1/cb/getNextDivisionTeams/${chainId}`);
      return response.data;
  } catch (error) {
      console.error(`Error loading next division teams:`, error.response ? error.response.data : error.message);
      throw error;
  }
}

export async function getTeamNameById(chainId, teamId) {
  // console.log("getting team name", teamId);
  try {
      const response = await axios.get(`/api/v1/cb/getTeamNameById/${chainId}/${teamId}`);
      return response.data;
  } catch (error) {
      console.error(`Error loading team name for team with ID ${teamId}:`, error.response ? error.response.data : error.message);
      throw error;
  }
}


export async function createDivision(chainId, teamIds) {

  let data ={}; let result = {};
  data.teamIds = teamIds;
  data.chainId = chainId;
  const postData = {
      title: 'Create division',
      data: data,
    };
  await axios.post(`/api/v1/cb/createDivision/${chainId}`, postData)
  .then((response) => {
          result = response.data;
  })
  .catch((error) => {
        console.error('Error:', error);
        return null;
  });
  return result;
}


