import {React, useCallback, useEffect,useState} from "react";
import { Link } from 'react-router-dom';
import Web3 from 'web3';
// Components
import {Col,Row, Spinner} from 'react-bootstrap';
import DownloadWalletModal from './modal/DownloadWalletModal';
// login 
import * as login from '../helpers/login';
// Misc
import { CopyToClipboard } from 'react-copy-to-clipboard';

import * as IoIcons from 'react-icons/io';

function LoginBanner({loginButtonFallback,signer,loginMethod,startTimer,isMobile}) {
    const [signer_, setSigner] = useState(undefined)
    const [ownedSafes, setOwnedSafes] = useState({safes: ['']})
    const [safeAuthKit, setSafeAuthKit] = useState(undefined)
    const [loginLoading, setLoginLoading] = useState(false)
    const [accountsLoading, setAccountsLoading] = useState(false)
    const [accountCopied, setAccountCopied] = useState(false)
    const [timeRemaining, setTimeRemaining] = useState(5 * 60 + 30);
    const [openDownloadMetaMask, setOpenDownloadMetaMask] = useState(false)

    const rowStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#57ef7c",
        minWidth: "100vw",
        boxShadow: '0 4px 2px -2px black',
        margin: 0,
    };

    const rowStyleMobile = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#57ef7c",
        minWidth: "100vw",
        boxShadow: '0 4px 2px -2px black',
        position:"relative!important",
        margin: 0,
    };


    useEffect(() => {
        // console.log(startTimer)
        let intervalId;
        if (startTimer) {
          // Set up an interval that ticks every second
          intervalId = setInterval(() => {
            setTimeRemaining((time) => {
              if (time >= 1) return time - 1;
              // When countdown finishes, clear the interval and stop the timer
              clearInterval(intervalId);
              window.location.reload();
              return 0;
            });
          }, 1000);
        }
    
        // Clean up the interval on unmount
        return () => {
          if (intervalId) clearInterval(intervalId);
        };
      }, [startTimer]);


    function truncate(str) {
        return str.substring(2, 8);
    }

    async function handleSocialSignInClick(){
        if(signer == undefined){
            await socialSignIn();
        } else {
            console.log("sign out")
            await signOut();
        }
    }

    async function handleWalletSignInClick(){
        await walletSignIn();
    }

    async function walletSignIn(){
        setLoginLoading(true);
        let web3; let signer_; let loginMethod;
        try {
            const web3Result = await web3SignIn();
            signer_ = web3Result.signer_;
            web3 = web3Result.web3;
            loginMethod = "WEB3";
            if (web3 === undefined) {
                setLoginLoading(false);
                setOpenDownloadMetaMask(true);
                return;
            }
        } catch (e) {
            console.log("WEB3 Sign In failed:", e);
            setLoginLoading(false);
            setOpenDownloadMetaMask(true);
            return;
        }
        // STATE UPDATE
        setSigner(signer_);
        setLoginLoading(false);
        await login.accountChangeListener(accountListenerFallback);
    
        // Fallback to main page
        let loginData = prepLoginData(signer_, undefined, [], [], loginMethod, web3);
        loginButtonFallback(loginData);
    }

    async function socialSignIn() {
        setLoginLoading(true);
    
        let loginMethod;
        let signer_;
        let web3;
    
        // Attempt WEB AUTH Sign In first
        try {
            const webAuthResult = await webAuthSignIn();
            signer_ = webAuthResult.signer_;
            web3 = webAuthResult.web3;
            loginMethod = "WEB3AUTH";
        } catch (e) {
            console.log("WEB AUTH Sign In failed:", e);
            // If WEB AUTH Sign In fails, attempt WEB3 Sign In
            try {
                const web3Result = await web3SignIn();
                signer_ = web3Result.signer_;
                web3 = web3Result.web3;
                loginMethod = "WEB3";
                if (web3 === undefined) {
                    setLoginLoading(false);
                    setOpenDownloadMetaMask(true);
                    return;
                }
            } catch (e) {
                console.log("WEB3 Sign In failed:", e);
                setLoginLoading(false);
                setOpenDownloadMetaMask(true);
                return;
            }
        }
    
        // STATE UPDATE
        setSigner(signer_);
        setLoginLoading(false);
        await login.accountChangeListener(accountListenerFallback);
    
        // Fallback to main page
        let loginData = prepLoginData(signer_, undefined, [], [], loginMethod, web3);
        loginButtonFallback(loginData);
    }
    
    // Sub-function for WEB AUTH Sign In
    async function webAuthSignIn() {
        let safeAuthKit = await login.initAuthL2();
        let signInResponse = await safeAuthKit.signIn();
        let signer_ = signInResponse.eoa;
        const web3 = new Web3(safeAuthKit.getProvider());
        setSafeAuthKit(safeAuthKit);
        return { signer_, web3 };
    }
    
    // Sub-function for WEB3 Sign In
    async function web3SignIn() {
        let accounts = await login.connectWeb3Wallet();
        if (!accounts || accounts.length === 0) {
            throw new Error("No accounts found during WEB3 Sign In.");
        }
        let signer_ = accounts[0];
        let web3 = await login.loadWeb3Provider();
        
        return { signer_, web3 };
    }
    

    async function signOut(){
        if (loginMethod === "WEB3AUTH") {
            await safeAuthKit.signOut();
        }
        setSigner(undefined)
        setLoginLoading(false)
        // Fallback to main page
        let loginData = prepLoginData(undefined,undefined,[],"",[])
        loginButtonFallback(loginData)
    }

    const accountListenerFallback = useCallback(async (callbackData) => {
        let loginData = prepLoginData(callbackData.signer,undefined,[],[],"",undefined);
        loginButtonFallback(loginData)
    }, []);

    const formatTime = () => {
        const minutes = Math.floor(timeRemaining / 60);
        const seconds = timeRemaining % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <>
        {!isMobile ? (
            <>
            <Row style={rowStyle}>
                <Col xs={2} >
                </Col>
                <Col xs={startTimer ? 3 : 7} >
                    {signer != undefined ? (
                        <>
                        <div style={{paddingLeft: '4%'}}>
                            <span className= 'table-title' style={{color: "black",float: "centre"}}>
                                Manager Id: 
                            </span>
                            <span className= 'sub-title-mobile' > 
                                {truncate(signer)} 
                            </span>
                            <CopyToClipboard text={signer} onCopy={() => handleCopyAccount()}>
                                <span  style={{color: "black", cursor: "pointer"}}>
                                { 
                                    accountCopied ? <IoIcons.IoIosCheckmark/> : <IoIcons.IoMdCopy/>
                                }
                                </span>
                            </CopyToClipboard>
                        </div>
                        </>
                    ):(
                        <>
                            {accountsLoading ? (
                                <div >
                                    Loading your accounts...
                                </div>
                            ):(
                                <div >
                                {/* Sign in to load your accounts. */}
                                </div>
                            )}
                        </>
                    )}
                </Col>
                {startTimer && (
                    <>
                    <Col xs={3}>
                        <div style={{fontStyle: "italic"}}>
                            Setting the field. Your journey to league glory begins shortly...
                        </div>
                    </Col>
                    <Col xs={2}>
                        <div>
                            <button className='button-1' >
                                Countdown: {formatTime()}
                            </button>
                        </div>
                    </Col>
                    </>
                )}
                <Col xs={isMobile ? 6 : 3} style={!isMobile ? {paddingLeft:"5%"}:{}}>
                {loginLoading ? (
                    // <button style={{paddingTop:'15%', background: 'transparent', border:'none', outline:'none'}} onClick={() => handleSignInClick()}>
                    //     <Spinner animation="border" variant="dark" />
                    // </button>
                    <Row>
                    <Col md="auto">
                        <button className='gradient-button' onClick={() => handleSocialSignInClick() }> 
                            <Row>
                                <Col md="auto">
                                    <div className="sub-title-mobile" >
                                        Signing in..
                                        <span>
                                            <Spinner animation="border" variant="dark" style={{ borderWidth: '4px', height: '1rem', width: '1rem' }} />
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </button>
                    </Col>
                </Row>
                ):(
                    <Row>
                        <Col md="auto">
                            <button className='gradient-button' 
                                onClick={() => handleSocialSignInClick()}
                                style={signer == undefined ? {} : {background: 'rgb(215, 218, 214)'}}>
                                <Row>
                                    <Col md="auto">
                                        <div className="sub-title-mobile">
                                            {signer == undefined ? (
                                                <>
                                                    Sign In <IoIcons.IoIosFlash />
                                                </>
                                            ) : (
                                                'Sign Out'
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </button>
                        </Col>
                        {signer == undefined  && (
                            <Col className="col-centered comic-neue" md="auto">
                                <button className='button-1-nopad' onClick={() => handleWalletSignInClick()}> 
                                    <img src="./mm-logo.svg" style={{height: '20px', width: '20px'}}/>
                                </button>
                            </Col>
                        )}
                    </Row>
                )}
                </Col>
            </Row>
            {/* <Row className="fy-content-center">
                <Col>
                {signer != undefined && ownedSafes.safes.length < 1 ? (
                    <>
                    <div style={{color: "white"}}>
                        No Account Found.
                    </div>
                    <button className='button-1' onClick={() => handleCreateAccountClick()}> Create <IoIcons.IoIosFlash/> </button>
                    </>
                ):null}
                </Col>
            </Row> */}
            </>
        ):(
            <div >
                <Row style={rowStyleMobile}>
                    <Col xs={2} style={{color: 'white',fontStyle: 'italic',position: "absolute", top: "2%", left:"25%"}}>
                        <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className='sub-title-mobile' style={{paddingLeft:"10%",fontSize: '20pt'}}>CHAINBALLERS</div>
                        </Link>
                    </Col>
                </Row>
                <Row className="justify-content-center" style={rowStyleMobile}>
                    <Col xs={10}>
                        {signer != undefined ? (
                            <>
                            <div >
                                <span className= 'table-title' style={{color: "black"}}>
                                    Manager Id: 
                                </span>
                                <span className= 'sub-title-mobile' > 
                                    {truncate(signer)} 
                                </span>
                                <CopyToClipboard text={signer} onCopy={() => handleCopyAccount()}>
                                    <span style={{color: "white",cursor: "pointer"}}>
                                    { 
                                        accountCopied ? <IoIcons.IoIosCheckmark/> : <IoIcons.IoMdCopy/>
                                    }
                                    </span>
                                </CopyToClipboard>
                            </div>
                            </>
                        ):(
                            <>
                                {accountsLoading ? (
                                    <div style={{color: "white"}}>
                                        Loading your accounts...
                                    </div>
                                ):(
                                    <div style={{color: 'white'}}>
                                    {/* Sign in to load your accounts. */}
                                    </div>
                                )}
                            </>
                        )}
                    </Col>
                    <Col xs="auto">
                        {loginLoading ? (
                            <button style={{paddingTop:'15%', background: 'transparent', border:'none', outline:'none'}} onClick={() => handleSocialSignInClick()}>
                                <Spinner animation="border" variant="dark" />
                            </button>
                        ):(
                            <button className='gradient-button' 
                                onClick={() => handleSocialSignInClick()}
                                style={signer == undefined ? {} : {background: ' rgb(250, 255, 249)'}}
                            > 
                                <Row className="justify-content-center">
                                    <Col md="auto" xs="auto">
                                        <div className="sub-title-mobile">
                                            {signer == undefined ? (
                                                <>
                                                    Sign In <IoIcons.IoIosFlash />
                                                </>
                                            ) : (
                                                'Sign Out'
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </button>
                        )}
                    </Col>
                </Row>
            </div>
        )}
        <DownloadWalletModal
            show={openDownloadMetaMask}
            onHide={() => setOpenDownloadMetaMask(false)}
        />
        </>
    );

    function handleCopyAccount(){
        setAccountCopied(true)
        setTimeout(() => {
            setAccountCopied(false);
        }, 2000);
    }

    function prepLoginData(signer,selectedSafe,ownedSafes,ownedSafesData,loginMethod,web3){
        // console.log(signer,selectedSafe,ownedSafes,ownedSafesData,web3)
        let loginData = {}
        loginData.signer = signer;
        // loginData.signer ="0x7bF8ee10E37498b1338c64458ec3a546F38BC40D" ;
        loginData.selectedSafe = selectedSafe;
        loginData.ownedSafes = ownedSafes;
        loginData.ownedSafesData = ownedSafesData;
        loginData.loginMethod = loginMethod;
        if (web3 != undefined){
            loginData.web3 = web3;
        } 
        return loginData;
    }
    
    function prepAccountsData(selectedSafe,nav,ownedSafes,ownedSafesData){
        let accountsData = {}
        accountsData.selectedSafe = selectedSafe;
        accountsData.nav = nav;
        accountsData.ownedSafes = ownedSafes;
        accountsData.ownedSafesData = ownedSafesData;
        return accountsData;
    }
    
}

    export default LoginBanner;