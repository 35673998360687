import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Figure, Row } from 'react-bootstrap';
import TeamListAndAttributes from '../components/nft/TeamListAndAttributes';
import INFTContract from '../contracts/INFTContract.json'
import SignInButton from '../components/SignInButton';
import PlayerCardSingle from '../components/nft/PlayerCardSingle';
import TrainingRoomModal from '../components/modal/TrainingRoomModal';
import SkillBar from '../components/skillBar/SkillBar';
// helpers
import * as players from '../helpers/players.js'; 
import * as api from '../helpers/api.js';
import * as coreWeb3 from '../helpers/coreWeb3.js';
// static
import backgroundImage from "../static/training-ground-2.png";

class Training extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account: 0,
      selectedItem: 0,
      selectedAttribute: 0,
      emptyCard: {
        id: 0
      },
      countdown: null
    }
    this.handleSelect = this.handleSelect.bind(this)
    this.updateCountdown = this.updateCountdown.bind(this)
    this.setCanTrainMappingFromServer = this.setCanTrainMappingFromServer.bind(this)
    this.changeSelection = this.changeSelection.bind(this)
  }
  
  async componentDidMount(){
    await this.checkSize();
    await this.init();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async checkSize() {
    this.setState({
        isMobile : window.innerWidth < 1000
    });
  }

  async init(){
    await this.props.trainingCallback();
  }

  setInitialSkillLevel(skillValue){
    // console.log(skillValue)
    this.setState({
      initialSkillLevel: skillValue
    })
  }

  setShowTrainingRoomModal(status){
    this.setState({
      showTrainingRoomModal: status
    })
  }

  setSubmittedAttempt(status){
    this.setState({
      submittedAttempt: status
    })
  }

  setLevelUpAttempt(status){
    this.setState({
      levelUpAttempt: status
    })
  }

  setLevelUpResult(result){
    this.setState({
      levelUpResult: result
    })
  }

  setAttemptResult(result){
    this.setState({
      trainingAttempt: result
    })
  }

  handleCloseTrainingRoomModal(){
    this.setState({
      showTrainingRoomModal: false,
      trainingAttempt: false,
      levelUpAttempt: false,
      levelUpResult: false
    })
  }
  
  handleSelect(item){
    this.setState({
      selectedItem: item
    })
    // console.log(item)
    this.updateCountdown(item);
    this.interval = setInterval(this.updateCountdown, 1000);
  }

  handleSelectAttribute(attIndex){
    this.setState({
      selectedAttribute: attIndex
    })
  }

  updateCountdown(selectedItem){
    if (selectedItem === undefined){
      selectedItem = this.state.selectedItem
    }
    const timestamp = this.state.canTrainMapping[selectedItem.tokenId][1];
    const now = Math.floor(Date.now() / 1000);
    const timeLeft = timestamp - now; // calculate time difference in milliseconds
    // console.log(timestamp,now, timeLeft)
    if (timeLeft > 0) {
      // Convert timeLeft to a readable format (e.g., HH:MM:SS)
      const days = Math.floor(timeLeft / 86400).toString().padStart(2, '0');
      const hours = Math.floor((timeLeft % 86400) / 3600).toString().padStart(2, '0');
      const minutes = Math.floor((timeLeft % 3600) / 60).toString().padStart(2, '0');
      const seconds = Math.floor(timeLeft % 60).toString().padStart(2, '0');
      this.setState({ countdown: `${days} days ${hours} hours ${minutes}:${seconds}` });
    } else {
      clearInterval(this.interval);
      this.setState({ countdown: "Time's up!" });
    }
  };

  async hasLevelledUp(tokenId, attIndex){
    let initialSkillLevel = this.state.initialSkillLevel;
    let currentSkillLevel = 0;
    const CBContract = new this.props.web3.eth.Contract(INFTContract.abi, this.props.CBAddress);
      await CBContract.methods.getSkillByIndex(tokenId, attIndex).call().then(
        result => {
          // console.log(initialSkillLevel, result)
          currentSkillLevel = result
          if (currentSkillLevel > initialSkillLevel){
            return this.setLevelUpResult("success")
          } else {
            return this.setLevelUpResult("failed")
          }
        })
  }

  async handleTraining(player, attIndex){
    let network = await this.props.web3.eth.net.getId();
    if (network !== 8453){
      await coreWeb3.switchToChain(this.props.web3, "0x2105");
    }
    if (network !== 8453){
      return;
    }
    console.log("training",player, attIndex, this.props.account)
    let data = "0x"
    // trainUp
    this.setInitialSkillLevel(player.skillsValue[attIndex])
    this.setShowTrainingRoomModal(true)
    let web3 = this.props.web3;
    if (web3 !== undefined){

      const CBContract = new web3.eth.Contract(INFTContract.abi, this.props.CBAddress);
      await CBContract.methods.trainUp(player.tokenId, attIndex, data).send({ 
        from: this.props.account,
        maxPriorityFeePerGas: null, 
        maxFeePerGas: null
      }).on('receipt', receipt =>
        {
          console.log(receipt)
          this.setSubmittedAttempt(true)
          CBContract.methods.getLastTrainAttemptResult(player.tokenId).call().then(
            result => {
              console.log(result)
              this.setSubmittedAttempt(false)
              this.setAttemptResult(result)
              if (result){
                this.setLevelUpAttempt(true)
                // tryLevelUp (within 3 min)
                CBContract.methods.tryLevelUp(player.tokenId).send({ 
                  from: this.props.account,
                  maxPriorityFeePerGas: null, 
                  maxFeePerGas: null
                }).on('receipt', receipt =>
                  {
                    console.log(receipt)
                    this.setLevelUpAttempt(false)
                    this.hasLevelledUp(player.tokenId, attIndex)                  
                  }
                )
              } else {
                this.setLevelUpResult("failed")
              }
            }
          )
        }
      )
      await this.setCanTrainMappingFromServer(this.props.ownedTeamId)
    }
  }

  async setCanTrainMapping(ownedPlayers){
    let canTrainMapping = []
    const output = {};
    let web3 = this.props.web3;
    if (web3 !== undefined) {
      const CBContract = new web3.eth.Contract(INFTContract.abi, this.props.CBAddress);
      // Create an array of promises
      // console.log(ownedPlayers)
      const promises = ownedPlayers.map(item => {
        return CBContract.methods.canTrain(item.baller_id).call()
          .then(result => {
            // console.log(result)
            output[item.baller_id] = result;
          });
      });
      // Use Promise.all to wait for all promises to resolve
      await Promise.all(promises)
        .then(() => {
          // results is an array of values from each resolved promise
          canTrainMapping = output;
        })
        .catch(error => {
          // Handle any error that occurred in any of the promise
          console.error(error);
        });
    }
    this.setState({
      canTrainMapping: canTrainMapping
    })
    // console.log(canTrainMapping)
    return canTrainMapping
  }

  async setCanTrainMappingFromServer(teamId){
    let canTrainMapping = []
    canTrainMapping = await api.getTrainingStatus(8453,teamId);

    this.setState({
      canTrainMapping: canTrainMapping
    })
    return canTrainMapping
  }

  changeSelection(role, toChangeId, side ){
    let index = this.props.ownedPlayers.findIndex((item) => item.baller_id === toChangeId);
    let newPlayerData = {}
    if (side === "Back"){ 
      newPlayerData = index > 0 ? this.props.ownedPlayers[index - 1] : this.props.ownedPlayers[0];
    } else {
      newPlayerData = index < this.props.ownedPlayers.length - 1 ? this.props.ownedPlayers[index + 1] : this.props.ownedPlayers[0];
    }
    let title = "Baller #"+ newPlayerData.baller_id;
    let tokenId = newPlayerData.baller_id;
    let imgSrc = players.uriToImage(newPlayerData.baller_uri);
    const [skillsName, skillsValue] = players.getSkillsFromPlayerData(newPlayerData)
    newPlayerData = {title, tokenId, imgSrc,skillsName,skillsValue}
    this.setState({
      selectedItem: newPlayerData
    })
  }

  render(){
    // console.log(this.props.ownedPlayers)
    return (
      <>
      <div style={ !this.props.isMobile ? {paddingTop: "2px",paddingLeft: "7%"} : {paddingTop: "2px"}}>
        <div style={this.props.ownedTeamId !== undefined && this.props.ownedTeamId > 0 ? {
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${backgroundImage})`, 
            backgroundBlendMode: 'soft-light',
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            minHeight: window.innerHeight,
            minWidth: '100%'
        }: null}>
        {this.props.account !== undefined ? (
            <>
            <Row style={{display: 'flex', justifyContent: 'center', paddingTop:"1%"}}>
                  <div className='sub-title-mobile'>
                  Training Grounds
                </div>
            </Row>
            <Row style={{display: 'flex', justifyContent: 'center', paddingBottom: "1%"}}>
                  <div className='table-title'>
                  Select Player
                </div>
            </Row>
            </>
            ):(
            <Row style={{display: 'flex', justifyContent: 'center', paddingTop:"1%", paddingBottom: "1%"}}>
                  <div className='sub-title-mobile'>
                  Training Grounds
                </div>
            </Row>
        )}
        <Container style={!this.props.isMobile ? {minWidth: '1000px', minHeight: window.innerHeight}:null}>
          {this.props.account !== undefined ? (
            <>
            {this.props.ownedPlayers === undefined || this.props.ownedPlayers.length === 0 ? (
              <Row className="justify-content-center" style={{ paddingTop: '1%'}}>
                <Col md="auto" xs="auto">
                  <Link to="/app" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <button className="button-1">
                      No players available to train. Create a team!
                    </button>
                  </Link>
                </Col>
              </Row>
            ):(
              <>
              <Row className="justify-content-center"  style={{paddingLeft: '2%', paddingBottom: '2%'}}>
                <>
                  <Container style={{paddingTop:"2%"}}>
                    <TeamListAndAttributes
                      account = {this.props.account}
                      web3 = {this.props.web3}
                      CBAddress = {this.props.CBAddress}
                      ownedPlayers={this.props.ownedPlayers}
                      ownedTeamId={this.props.ownedTeamId}
                      handleselect = {this.handleSelect}
                      setCanTrainMapping = {this.setCanTrainMappingFromServer}
                      isMobile={this.props.isMobile}
                      key={this.props.ownedPlayers}
                    />
                  </Container>
                </>
              </Row>
              </>
            )}
            </>
          ):(
          <Row className="justify-content-center" style={{ paddingTop: '0%'}}>
            <Col md="auto" xs="auto">
              <SignInButton
                loginButtonFallback={this.props.loginButtonFallback}
                customText={'Sign in to display your players'}
                accountListenerFallback={this.props.accountListenerFallback}
                isMobile={this.props.isMobile}
              />
            </Col>
          </Row>
          )}
            <div style={{paddingBottom: "2%"}}>
              <Container 
                className='box-shadow-simple-2'
                style={!this.props.isMobile ? {backgroundColor: '#fff', minWidth: '900px', maxWidth: '600px', minHeight: "80px", borderRadius: "15px", boxShadow: '0px 10px 24px rgba(0, 0, 0, 0.2)', paddingTop: "2%", paddingBottom: "2%"}: {backgroundColor: '#fff',borderRadius: "15px", boxShadow: '0px 10px 24px rgba(0, 0, 0, 0.2)', paddingTop: "2%", paddingBottom: "2%"}}>
                <Row className="justify-content-center"  style={{paddingLeft: '2%', paddingBottom: '2%'}}>
                  <Col md ="auto" xs="auto">
                    <div className='sub-title-mobile'>
                      Training Room
                    </div>
                  </Col>
                </Row>
                {this.state.selectedItem === 0 ? (
                    <>
                      {/* empty card layout */}
                      <Row className="justify-content-center"  >
                        <Col md={5} xs="auto" style={{display: "flex", justifyContent: "center"}}>
                          <Figure className="justify-content-center" >
                            <Row>
                              <PlayerCardSingle
                                cardData = {this.state.selectedItem} 
                                cardSelection = {this.state.selectedItem.tokenId}
                                cardFunction={this.changeSelection}
                              />
                            </Row>
                          </Figure>
                        </Col>
                        <Col md={7} xs="auto" style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                          <Row style={{ paddingLeft:"1%"}}> 
                            <Col className="justify-content-center">
                              <div style={{fontStyle:"italic"}}>
                                Player not selected.
                              </div>
                            </Col>
                          </Row>
                          <Row style={{ paddingLeft:"1%"}}> 
                            <Col className="justify-content-center"> 
                              <div style={{fontStyle:"italic"}}>
                                No attributes to display.
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                ):(
                  <>
                  {this.state.canTrainMapping[this.state.selectedItem.tokenId] && (
                  <>
                  {this.state.canTrainMapping[this.state.selectedItem.tokenId][0] ? (
                    <Row className="justify-content-center">
                      <Col md={5} xs="auto" style={{display: "flex", justifyContent: "center"}}>
                        <Figure className="justify-content-center" >
                          <Row style={{ paddingLeft:"5%"}}>
                            <PlayerCardSingle
                              cardData = {this.state.selectedItem} 
                              cardSelection = {this.state.selectedItem.tokenId}
                              cardFunction={this.changeSelection}
                            />
                          </Row>
                          <Row style={{ paddingLeft:"5%", paddingTop: '5%'}} className="justify-content-center">
                              <div style={{fontWeight: "bold"}}>
                                {this.state.selectedItem.title}
                              </div>
                          </Row>
                          <Row style={{ paddingLeft:"5%", paddingTop: '5%'}} className="justify-content-center">
                            <Col className="d-flex justify-content-center align-items-center">
                              <button 
                                className="gradient-button" 
                                style={this.props.startTimer ? { cursor: 'not-allowed'}:null}
                                onClick={() => this.handleTraining(this.state.selectedItem, this.state.selectedAttribute)}
                                >
                                  <div className='sub-title-mobile'>
                                  Train 
                                  </div>
                              </button> 
                            </Col>
                          </Row>
                        </Figure>
                      </Col>
                    {this.state.selectedItem !== undefined ? (
                      <>
                      <Col md={7} xs="auto" style={{ display: "flex", justifyContent: "flex-start", paddingLeft: '3%' }}>
                        <Row style={{ paddingLeft:"1%",paddingBottom: "3%"}}> 
                          <table style={{fontSize: '1rem', width:"100%",borderRadius:"15%", textAlign:"center"}}>
                            <thead>
                              <tr>
                                <th>
                                  {this.state.selectedItem.body}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.selectedItem.skillsName.map((row, index) => (
                                <>
                                {this.state.selectedItem.skillsName[index] !== "Learning Rate" && (
                                  <tr key={index}>
                                    <td>
                                      {row}
                                    </td>
                                    <td style={{paddingLeft: "2%"}}>
                                      <div style={{width: "100px"}}>
                                        <SkillBar
                                          skillValue = {this.state.selectedItem.skillsValue[index]}
                                          skillName = {this.state.selectedItem.skillsName[index]}
                                          isMobile = {this.props.isMobile}
                                        />
                                      </div>
                                    </td>
                                    <td style={{paddingLeft:"10%"}}>
                                      <div 
                                        className="sub-title-mobile"
                                      >
                                        {this.state.selectedItem.skillsValue[index]}
                                      </div>
                                    </td>
                                    <td style={{paddingLeft: "15%"}}>
                                      {this.state.selectedAttribute === index ? (
                                        <button className="button-1-nopad-selected" style = {{height: "50%"}}onClick={() => this.handleSelectAttribute(index)}>
                                          <div 
                                            className='sub-title-mobile'
                                            style={{fontSize: "1rem"}}
                                          >
                                          Selected
                                          </div>
                                        </button> 
                                      ):(
                                        <button className="button-1-nopad" style = {{height: "50%"}}onClick={() => this.handleSelectAttribute(index)}>
                                          Select
                                        </button> 
                                      )}
                                    </td>
                                  </tr>
                                )}
                                </>
                              ))}
                            </tbody>
                          </table>
                        </Row>
                      </Col>
                      </>
                    ):null} 
                    </Row>
                  ):(
                    <>
                    <Row className="justify-content-center">
                      <Col md={3} xs="auto" style={{display: "flex", justifyContent: "flex-start"}}>
                        <Figure className="justify-content-center" >
                          <Row style={{ paddingLeft:"5%"}}>
                            <PlayerCardSingle
                              cardData = {this.state.selectedItem} 
                              cardSelection = {this.state.selectedItem.tokenId}
                              cardFunction={this.changeSelection}
                            />
                          </Row>
                          <Row style={{ paddingLeft:"5%", paddingTop: '5%'}} className="justify-content-center">
                          {this.state.selectedItem.title}
                          </Row>
                        </Figure>
                      </Col>
                      <Col md={3} xs="auto" style={{display: "flex", justifyContent: "flex-start", paddingLeft: '3%' }}>
                        <Row style={{ paddingLeft:"1%"}}> 
                          <table style={{fontSize: '1rem', width:"100%",borderRadius:"15%", textAlign:"center"}}>
                            <thead>
                              <tr>
                                <th>
                                  {this.state.selectedItem.body}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.selectedItem.skillsName.map((row, index) => (
                                <>
                                <tr key={index}>
                                  <td>{row}</td>
                                  <td>{this.state.selectedItem.skillsValue[index]}</td>
                                </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </Row>
                      </Col>
                    </Row>
                    <Row style={{ paddingLeft:"5%"}} className="justify-content-center">
                      <Col md="auto" className="d-flex justify-content-center align-items-center">
                      Player already recently trained. He will be ready in:
                      </Col>
                      <Col md="auto">
                        <div 
                          className="button-1" 
                          style={this.props.startTimer ? { cursor: 'not-allowed'}:null}
                          >
                            {this.state.countdown}
                        </div> 
                      </Col>
                    </Row>
                    </>
                  )}
                  </>
                  )}
                </>
                )
              }
            </Container>
          </div>
        </Container>
        <TrainingRoomModal
          show={this.state.showTrainingRoomModal}
          submittedAttempt={this.state.submittedAttempt}
          trainingAttemptResult={this.state.trainingAttempt}
          levelUpAttempt={this.state.levelUpAttempt}
          levelUpResult={this.state.levelUpResult}
          onHide={() => this.handleCloseTrainingRoomModal()}
          isMobile={this.props.isMobile}
        />
        </div>
      </div>
      </>
    );
  }
}

export default Training;
