import axios from 'axios';

export async function createStripePaymentIntent(amount, currency) {
    try {
        const response = await axios.post('/api/v1/pay/create-payment-intent', {
            amount: amount, // e.g., 5000 for $50.00
            currency: currency // e.g., 'usd'
        });

        const { clientSecret } = response.data;
        return clientSecret;

    } catch (error) {
        console.error('Error creating payment intent:', error);
    }
}

