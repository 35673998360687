import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as Io5Icons from 'react-icons/io5';
import { FaTelegram } from 'react-icons/fa';
import { FaSquareXTwitter, FaSquareInstagram, FaShareNodes } from 'react-icons/fa6';

const ShareTeamModal = ({ show, onHide, isMobile }) => {

    const shareText = "Create a Team on Chainballers!";
    
    const shareOnTwitter = () => {
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=https://chainballers.xyz`;
        window.open(twitterUrl, '_blank');
    };

    const shareOnInstagram = () => {
        const instagramUrl = `https://www.instagram.com/?caption=${encodeURIComponent(shareText)}`;
        window.open(instagramUrl, '_blank');
    };

    const shareOnDiscord = () => {
        const discordUrl = `https://discord.com/channels/123456789012345678/123456789012345678?text=${encodeURIComponent(shareText)}`;
        window.open(discordUrl, '_blank');
    };

    const shareOnTelegram = () => {
        const telegramUrl = `https://t.me/share/url?url=https://chainballers.xyz&text=${encodeURIComponent(shareText)}`;
        window.open(telegramUrl, '_blank');
    };

    return (
        <Modal 
            show={show} 
            onHide={onHide} 
            centered 
            size="md" 
            aria-labelledby="contained-modal-title-vcenter" 
            className="rounded-modal" 
            style={isMobile ? { maxWidth: '390px' } : null }
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{paddingLeft: "2%"}}>
                Invite others to create a team!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={!isMobile ? "share-team-box" : "share-team-box-mobile"}>
                    {/* <h4 className="text-center">Invite others to create a team!</h4> */}
                    <div style={{paddingTop: "2%", textAlign: 'center'}}>
                        Share on:
                    </div>
                    <div className="text-center">
                        <Button className="share-buttons" onClick={shareOnTwitter} variant="outline-primary" style={{margin: "10px"}}>
                            Twitter
                            <FaSquareXTwitter style={{ marginLeft: '8px' }} />
                        </Button>
                        <Button className="share-buttons" onClick={shareOnInstagram} variant="outline-danger" style={{margin: "10px"}}>
                            Instagram
                            <FaSquareInstagram style={{ marginLeft: '8px' }} />
                        </Button>
                        <Button className="share-buttons" onClick={shareOnDiscord} variant="outline-dark" style={{margin: "10px"}}>
                            Discord
                            <Io5Icons.IoLogoDiscord style={{ marginLeft: '8px' }} />
                        </Button>
                        <Button className="share-buttons" onClick={shareOnTelegram} variant="outline-info" style={{margin: "10px"}}>
                            Telegram
                            <FaTelegram style={{ marginLeft: '8px' }} />
                        </Button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='button-1b' onClick={onHide}>Close</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShareTeamModal;
