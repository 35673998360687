import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  {
    title: 'Team',
    path: '/app',
    icon: <AiIcons.AiFillHome className="bordered"/>,
    cName: 'nav-text'
  },
  {
    title: 'League',
    path: '/schedule',
    icon: <FaIcons.FaRegCalendarAlt className="bordered"/>,
    cName: 'nav-text'
  },
  {
    title: 'Formation',
    path: '/formation',
    icon: <IoIcons.IoMdOptions className="bordered"/>,
    cName: 'nav-text'
  },
  {
    title: 'Training',
    path: '/training',
    icon: <FaIcons.FaDeezer />,
    cName: 'nav-text'
  },
  {
    title: 'Market',
    path: '/market',
    icon: <FaIcons.FaRegHandshake />,
    cName: 'nav-text'
  },
  {
    title: 'Explore',
    path: '/explore',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
  {
    title: 'Docs',
    path: '/docs',
    icon: <AiIcons.AiOutlineRead />,
    icon2: <FaIcons.FaExternalLinkAlt/>,
    cName: 'nav-text'
  },
  {
    title: '',
    path: '',
    icon: '',
    cName: 'nav-text-no-hover'
  },
  // {
  //   title: 'Referral',
  //   path: '/referral',
  //   icon: <AiIcons.AiOutlineGift />,
  //   cName: 'nav-text'
  // }
];
