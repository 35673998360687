import React, { Component } from 'react';
import { Col, Container,Row, Spinner} from 'react-bootstrap';
import { Link } from 'react-router-dom';
// Components
import LeaguesGrid from '../components/leagues/LeaguesGrid';
import LeagueTable from '../components/leagues/LeagueTable';
import TeamList from '../components/nft/TeamList';
import TeamListLoaded from '../components/nft/TeamListLoaded';
import CouponCodeBox from '../components/forms/CouponCodeBox';
import SignInButton from '../components/SignInButton';
import TxModal from '../components/modal/TxModal';
import TxHandlingModal from '../components/modal/TxHandlingModal';
import NameChangeModal from '../components/modal/NameChangeModal';
import confetti from 'canvas-confetti';
import CreateTeamBox from '../components/forms/CreateTeamBox';
import LeagueSelection from '../components/leagues/leagueSelection/LeagueSelection';
// Contract for team creation abi
import INFTContract from '../contracts/INFTContract.json'
// Helpers
import * as login from '../helpers/login.js';
import * as api from '../helpers/api.js';
import * as coreWeb3 from '../helpers/coreWeb3.js';
import * as utils from '../helpers/utils.js';


class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formationArray: [4,4,2],
      signedMessage: '',
      couponLoading: false,
      txStatus: {currentStep: 0, totalSteps: 0},
    }
    document.body.style = 'background: #ffffff;';
    this.sendCustomTeamTx = this.sendCustomTeamTx.bind(this);
    this.createTeam = this.createTeam.bind(this);
    this.createTeamCouponCode = this.createTeamCouponCode.bind(this);
  }
  
  async componentDidMount(){
    // for (let i = 34; i < 44; i++){
    //   try {
    //     await api.deleteBaller(i);
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
    await this.checkSize();
    await this.props.dashboardCallback();
    if (this.props.account !== undefined && this.props.ownedTeamId === undefined){
      this.setState({loadingGasCost: true})
      await this.estimateGasCost()
      this.setState({loadingGasCost: false})
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.account !== prevProps.account) {
      if (this.props.ownedTeamLeague == 0){
        await api.addLeague(this.props.ownedTeamLeague, 1);
      }
      this.setState({loadingGasCost: true})
      await this.estimateGasCost();
      this.setState({loadingGasCost: false})
    }

    if (this.props.ownedTeamLeague !== prevProps.ownedTeamLeague) {
      if (this.props.ownedTeamLeague !== undefined && this.props.ownedTeamLeague > 0){
        // UPDATE CACHE
        api.addLeagueTeams(this.props.ownedTeamLeague, 1);
        api.updateAllLeagueTeams(this.props.ownedTeamLeague, 1);
        this.props.getLeagueData(this.props.ownedTeamLeague);
      }
    }
  }

  async checkSize() {
    this.setState({
        isMobile : window.innerWidth < 1000
    });
  }

  handleShowLeaguesClick(){
    if (this.state.showLeagues){
      this.setState({
        showLeagues : false
      })
    } else {
      this.setState({
        showLeagues : true
      })
    }
  }

  async handleShowLeagueRankingClick(){
    this.setState({
      loadingLeagueRanking : true
    })
    await this.props.getLeagueData(this.props.ownedTeamLeague)
    this.setState({
        showLeagueRanking : true,
        loadingLeagueRanking : false,
    })
  }

  
  handleSetFormationClick() {}

  setNameChangeModal(value){
    this.setState({
      showNameChangeModal: value
    })
  }

  makeConfetti = () => {
    confetti({
      zIndex: 999,
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  }

  async createTeam(){
    // console.log(this.props)
    // console.log(this.props.loginMethod)
    if (this.props.loginMethod === 'WEB3'){
      await this.createTeamWeb3();
    } else if (this.props.loginMethod === 'WEB3AUTH'){
      await this.createTeamWeb3Auth();
    }
  }

  async createTeamWeb3Auth(){
    try {
      await this.props.payWithStripe();
      // Further logic to create the team after payment
    } catch (error) {
        console.error('Error with stripe:', error);
    }
  }

  async createTeamWeb3(){
    let web3 = this.props.web3;
    let account = this.props.account;
    var network = await web3.eth.net.getId();

    if (network !== 1){
      try{
        await coreWeb3.switchToChain(web3, "0x1");
      } catch (error) {
        console.log(error)
      }
    }

    network = await web3.eth.net.getId();
    this.setState({
      network: network
    })


    try{
      let nftContract = new web3.eth.Contract(INFTContract.abi, this.props.CBAddressL1);
      const balance = await web3.eth.getBalance(account);
      const balanceInEther = web3.utils.fromWei(balance, 'ether'); 
      const gasEstimate = await nftContract.methods.createTeam().estimateGas({ from: account });
      const gasPrice = await web3.eth.getGasPrice();
      // console.log(gasEstimate, gasPrice)
      const maxTransactionFee = gasEstimate * gasPrice;
      const maxTransactionFeeInEther = web3.utils.fromWei(maxTransactionFee.toString(), 'ether');
      // console.log(maxTransactionFee)
      let maxAffordableFee = this.findMaxGasFee(gasEstimate,balanceInEther)
      console.log(maxAffordableFee)
      let maxAffordableTxFee = web3.utils.fromWei((gasEstimate * maxAffordableFee).toString(), 'ether');
      let minReasonableTxFee = web3.utils.fromWei((gasEstimate * 10000000000).toString(), 'ether');
      if (parseFloat(balanceInEther) < parseFloat(maxTransactionFeeInEther)) {
          console.log("Insufficient funds", balanceInEther, maxTransactionFeeInEther);
          this.setState({
            accountEthBalance: balanceInEther,
            txPrice: maxTransactionFeeInEther,
            maxTransactionFeeInEther: maxTransactionFeeInEther,
            gasEstimate: gasEstimate,
            gasPrice: gasPrice,
            maxAffordableFee: maxAffordableFee,
            maxAffordableTxFee: maxAffordableTxFee,
            minReasonableTxFee: minReasonableTxFee,
            showTxHandlingModal: true,
          })
      } else {
        this.setState({
          showTxModal: true
        })  
        await this.sendCreateTeamTx(nftContract, null, null)
      }
    } catch (error) {
        // console.log(error)
    }

    this.handleCloseTxModal();
  }

  onLeagueSelect = async (leagueId) => {
    await this.props.onLeagueSelect(leagueId);
  }

  async sendCreateTeamTx(nftContract, maxPriorityFeePerGas,maxFeePerGas){
    await nftContract.methods.createTeam().send({ 
      from: this.props.account,
      maxPriorityFeePerGas: maxPriorityFeePerGas, 
      maxFeePerGas: maxFeePerGas
    })
    .once('transactionHash', txHash => {
      let txStatus = this.state.txStatus;
      txStatus.currentStep = 1;
      this.setState({
        txStatus: txStatus
      })
      setTimeout(() => {
        console.log(txHash);
      }, 500);
      })
    .on('receipt', txReceipt =>
    {
      let txStatus = this.state.txStatus;
      txStatus.currentStep = 2;
      this.setState({
        txStatus: txStatus
      })
      console.log(txReceipt)
      this.props.loadTeamOnL1(txReceipt);
      this.makeConfetti();
      this.props.timerSetter();
    }
    )
  }

  async estimateGasCost() {
    let totalCostUSD = 0;
    let costData = await api.getNewTeamCost();
    if (costData !== 0){
      totalCostUSD = costData.cost.totalCostUSD;
    } else {
      totalCostUSD = "N/A";
    }
    this.setState({
      totalCostUSD : totalCostUSD
    })
  }

  async createTeamCouponCode(){
    let web3 = this.props.web3;
    let account = this.props.account;
    const network = await web3.eth.net.getId();

    if (network !== 8453){
      console.log("switching to chain")
      await coreWeb3.switchToChain(web3, "0x2105");
    }

    let signedMessage = await this.signTypedData(web3,account)
    this.setState({
      signedMessage: signedMessage
    })
    if (signedMessage == undefined){
        throw new Error("No signed message")
    }
    api.sendCouponCode(account,signedMessage)
    // check if coupon code is valid
    let couponStatus = await api.isCouponSponsored(account,signedMessage);
    // Disabled for mainnet
    // if (couponStatus){
    //   await api.createTeamFromCoupon(account,signedMessage)
    // }
  }

  handleCloseTxModal(){
    this.setState({
      showTxModal: false
    })
  }

  handleCloseTxHandlingModal(){
    this.setState({
      showTxHandlingModal: false
    })
  }

  async sendCustomTeamTx(inputETHCustomFee){
    let nftContract = new this.props.web3.eth.Contract(INFTContract.abi, this.props.CBAddressL1);
    this.setState({
      showTxHandlingModal: false,
      showTxModal: true
    })  
    let maxFeePerGas = null;
    if (inputETHCustomFee !== undefined){
      let inputCustomFee= this.props.web3.utils.toWei(inputETHCustomFee, 'ether') / this.state.gasEstimate;
      // console.log(Math.round(inputCustomFee))
      await this.sendCreateTeamTx(nftContract, null, Math.round(inputCustomFee))
    } else {
      maxFeePerGas = this.state.maxAffordableFee
      // console.log(maxFeePerGas)
      if (maxFeePerGas >= 10000000000){ //12gwei decent minimum
        await this.sendCreateTeamTx(nftContract, null, maxFeePerGas)
      } else {
        this.setState({
          showTxHandlingModal: false,
          showTxModal: false
        })
        window.alert("Your balance is too low. Please top up wallet to continue.")
      }
    }
  }

  findMaxGasFee(inputEstimate, accountBalance){
    var gasEstimate; var accountEthBalance;
    if (inputEstimate !== undefined){
      gasEstimate = inputEstimate;
    } else {
      gasEstimate = this.state.gasEstimate;
    }
    if (accountBalance !== undefined){
      accountEthBalance = accountBalance;
    } else {
      accountEthBalance = this.state.accountEthBalance;
    }
    // console.log(gasEstimate)
    // try with 320000000000000 in maxFeePerGas, if enough decrease 40000000000000 until 0
    let web3 = this.props.web3;
    let maxFeePerGas = 32000000000;
    for (let i = 0; i < 8; i++){
      // console.log(this.state.gasEstimate,maxFeePerGas)
      const maxTransactionFee = gasEstimate * maxFeePerGas;
      // console.log(maxTransactionFee)
      const maxTransactionFeeInEther = web3.utils.fromWei(maxTransactionFee.toString(), 'ether');
      if (parseFloat(accountEthBalance) > parseFloat(maxTransactionFeeInEther)) {
        return maxFeePerGas - 5000000000
      } else {
        maxFeePerGas = maxFeePerGas - 4000000000;
      }
    }
    return 0;
  }

  async signTypedData(web3,fromAddress){

    // Defining the message signing data content.
    const message= {
      to: fromAddress,
      players: 11,
      deadline: 1700000000, // 14th November 2023
    }

    const typedData = JSON.stringify({
      types: {
        EIP712Domain: [
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ],
        CreateTx: [
          { name: "to", type: "address" },
          { name: "players", type: "uint256"},
          { name: "deadline", type: "uint256" }
        ]
      },
      primaryType: "CreateTx",
      domain: {
        chainId: 8453,
        verifyingContract: this.props.CBAddress
      },
      message: message,
    });

    // console.log(typedData)

    const params = [fromAddress, typedData];

    const method = "eth_signTypedData_v4";
    // console.log(params)

    var signedMessage;
    if (!utils.isWeb3Wallet(web3)){
      return await this.sendDirectSigRequest(web3,method, params, fromAddress);
    } else {
        signedMessage = await this.sendAsyncSigRequest(web3,method,params,fromAddress).then((signedMessage) => {
        console.log('TYPED SIGNED: ' + JSON.stringify(signedMessage));
        return JSON.stringify(signedMessage).slice(1,-1);
      })
      .catch((error) => {
        console.error('ERROR', error);
      });
    }
    return signedMessage;
  }

  sendAsyncSigRequest(web3,method, params, fromAddress) {
    return new Promise((resolve, reject) => {
      web3.currentProvider.sendAsync(
        {
          method,
          params,
          from: web3.utils.toChecksumAddress(fromAddress),
        },
        function (err, result) {
          if (err) {
            reject(err);
          } else if (result.error) {
            reject(new Error(result.error.message));
          } else {
            resolve(result.result);
          }
        }
      );
    });
  }

  async sendDirectSigRequest(web3,method, params, fromAddress) {
    console.log(params, fromAddress)
    const signedMessage = await web3.currentProvider.sendAsync(
      {
        from: web3.utils.toChecksumAddress(fromAddress),
        method,
        params,
      }
    );
    return signedMessage;
  }

  async handleTeamNameClick(){
    this.setNameChangeModal(true)
  }

  async getWeb3Provider(){
    return await login.loadProvider()
  }

  render(){
    // console.log(this.props.ownedTeamLeague,this.state.showLeagueRanking)
    return (
      <>
        {this.props.account !== undefined ? (
          <>
          <Container className="justify-content-center" style={ !this.props.isMobile ? { paddingTop: "0%", paddingLeft: "7%",} : {paddingTop: "5%", maxWidth: '360px'}}>
            <Row className="justify-content-center" style={ !this.props.isMobile ? { paddingTop:'5%', paddingBottom: "2%"} : {paddingTop:'5%', paddingBottom: "2%"}}> 
                <Col md="auto" xs="auto">
                <div className='sub-title-mobile'>
                  Your&nbsp; Team
                </div>
                </Col>
              </Row>
          {/* SIGNED IN AND LOADING TEAM ID  */}
            {this.props.ownedTeamId === undefined ? (
              <Row className="justify-content-center">
                <span >
                  Loading team..  <Spinner animation="border" variant="dark" style={{borderWidth: '4px', height: '1rem', width: '1rem'}} />
                </span>
              </Row>
            ):(
              <>
              {/* IF SIGNED IN BUT NO TEAM IS OWNED  */}
              {this.props.ownedTeamId === 0 ? (
                <>
                  {this.props.ownedTeamLeague == 0 ? (
                    <div style={!this.props.isMobile ? {paddingBottom: "0%"} : {paddingTop: "15%"}}>
                      <LeagueSelection
                        createTeam = {this.createTeam}
                        onLeagueSelect = {this.props.onLeagueSelectFallback}
                        isMobile= {this.props.isMobile}
                      />
                    </div>
                  ):( 
                    <>
                      <CreateTeamBox
                        web3 = {this.props.web3}
                        totalCostUSD = {this.state.totalCostUSD}
                        loadingGasCost = {this.state.loadingGasCost}
                        createTeam = {this.createTeam}
                        createTeamCouponCode = {this.createTeamCouponCode}
                        couponLoading = {this.state.couponLoading}
                      />
                      {(this.state.signedMessage !== '' && this.state.signedMessage !== undefined) ? (
                        <Row className="justify-content-center">
                          <CouponCodeBox
                            code={this.state.signedMessage}
                            isMobile={this.props.isMobile}
                          />
                        </Row>
                      ):null}
                    </>
                  )}  
                </>
              ):(
                <>
                  {/* IF TEAM IS SELECTED  */}
                  <Row className="justify-content-center" >
                    <Col className="col-centered comic-neue" md="auto">
                      <div className='table-title'>
                        Team Id: 
                      </div>
                      {this.props.ownedTeamId === undefined ? 
                      (<span className='sub-title-mobile'> 
                      0
                      </span>
                      ):(
                        <span className='sub-title-mobile'> 
                        {this.props.ownedTeamId}
                        </span>
                      )
                      } 
                    </Col>
                    <Col className="col-centered comic-neue" md="auto">
                      <div className='table-title'>
                      Name: 
                      </div>
                      {this.props.ownedTeamName === undefined || this.props.ownedTeamName == '' ? 
                      <div onClick={() => this.handleTeamNameClick()}>
                        <span className='sub-title-mobile'> 
                          No Name
                        </span> 
                      </div> 
                        : 
                        <div onClick={() => this.handleTeamNameClick()}>
                          <span className='sub-title-mobile'> 
                            {this.props.ownedTeamName}
                          </span>
                        </div>}
                    </Col>
                  </Row>
                  <Row className="justify-content-center"  style={{paddingLeft: "2%"}}>
                    <TeamList
                      account = {this.props.account}
                      web3 = {this.props.web3}
                      CBAddress = {this.state.network === 1 ? this.props.CBAddressL1 : this.props.CBAddress}
                      ownedPlayers = {this.props.ownedPlayers}
                      loadingCallback = {this.loadingCallback}
                      handleselect={this.props.handlePlayerSelect}
                      key = {this.props.ownedPlayers}
                    />
                  </Row>
                  <Row  style={{paddingTop: "5%"}}>
                    <Col style={{display: 'flex',justifyContent: 'center' }}>
                      <Link to="/formation" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <button  onClick={() => this.handleSetFormationClick()} className='gradient-button'>
                          <div className='sub-title-mobile'>
                            Set Formation 
                          </div>
                        </button>
                      </Link>
                    </Col>
                  </Row>
                </>
              )}
              </>
            )}
            {/* LEAGUES SECTION IF IN LEAGUE */}
            {this.props.ownedTeamLeague > 0 && (
            <>
              <Row style={{paddingTop:'2%', display: 'flex', justifyContent: 'center', paddingRight:"2%"}}>
                <div className='sub-title-mobile'>
                  Team&nbsp; League
                </div>
              </Row>
              <Row style={{paddingTop:'1%', display: 'flex', justifyContent: 'center', paddingRight:"2%"}}>
                <Col md="auto">
                  <span className="col-centered comic-neue">
                    League  <span className='sub-title-mobile'> {this.props.ownedTeamLeague !== undefined ? this.props.ownedTeamLeague : "N/A"} </span>
                  </span>
                </Col>
                <Col md="auto">
                  <span className="col-centered comic-neue">
                    League Prize <span className='sub-title-mobile'> {this.props.leaguePrize !== undefined ? (this.props.leaguePrize + ' $'): "N/A"}  </span>
                  </span>
                </Col>
              </Row>
              <Row  className="justify-content-center" style={{paddingTop:'3%'}}>
                <span className='table-title'>
                  League Ranking
                </span>
              </Row>
              <Row className="justify-content-center" style={{paddingTop:'1%'}}>
                <Col md="auto" style={{minWidth: "900px"}}>
                  <LeagueTable
                    leagueId={this.props.ownedTeamLeague}
                    teamsData = {this.props.teamsData}
                    getLeagueData = {this.props.getLeagueData}
                  />
                </Col>
              </Row>
              <Col style={{display: 'flex',justifyContent: 'center' }}>
                  {this.state.showLeagues ? (
                    <button className="button-1" onClick={() => this.handleShowLeaguesClick()} > 
                      Hide Leagues
                    </button>
                  ): (
                    <button className="button-1 comic-neue" onClick={() => this.handleShowLeaguesClick()} > 
                      Show More Leagues
                    </button>
                  )}
              </Col>
              {this.state.showLeagues && (
                <>
                <Row className="justify-content-center" style={{ paddingTop:'2%', paddingBottom: '2%'}}>
                  <span className='table-title'>
                    Leagues
                  </span>
                </Row>
                <Row className="justify-content-center" style={this.props.isMobile ? {paddingLeft:"5%"}:null}>
                  <LeaguesGrid
                    getLeagueData = {this.props.getLeagueData}
                    teamsData = {this.props.teamsData}
                    getNumberOfLeagues = {this.props.getNumberOfLeagues}
                    isMobile={this.props.isMobile}
                  />
                </Row>
                </>
              )}
            </>
            )}
          </Container>
          </>
        ):(
          <>
          {/* IF NOT SIGNED IN */}
          <div style={{paddingTop: "0%"}}>
            <Container style={ !this.props.isMobile ? {paddingLeft: "2%",minWidth: '800px', maxWidth: '900px'} : {paddingTop: "0%"}}>
              <Row className="justify-content-center" style={{paddingTop:'10%', paddingBottom: "2%"}}>
                <Col md="auto" xs="auto">
                  <span className="sub-title" style={!this.props.isMobile ? {fontSize: "28pt"} : {fontSize: "20pt"}}>
                    Total League Prizes
                  </span>
                </Col>
              </Row>
              <Row className="justify-content-center"style={!this.props.isMobile ? {paddingBottom: "3%"} : {paddingBottom: "6%"}}>
                <Col md="auto" xs="auto">
                  <span className="sub-title">
                    1,000 $
                  </span>
                </Col>
              </Row>
              <Row className="justify-content-center" style={!this.props.isMobile ? {paddingBottom: "1%"} : {paddingBottom: "3%"}}>
                <Col md="auto" xs="auto">
                  <span className='sub-title' style={!this.props.isMobile ? {fontSize: "28pt"} : {fontSize: "20pt"}}>
                    Current Champions
                  </span>
                </Col>
              </Row>
              <TeamListLoaded
                teamData = {this.state.sampleTeam}
                isMobile = {this.props.isMobile}
              />
              <Row className="justify-content-center" style={{paddingBottom:'10%'}}>
                <Col md="auto" xs="auto">
                  <SignInButton
                    loginButtonFallback={this.props.loginButtonFallback}
                    customText={'Join Now'}
                    accountListenerFallback={this.props.accountListenerFallback}
                    isMobile={this.props.isMobile}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          </>
        )}
      <TxModal
        show={this.state.showTxModal}
        onHide={() => this.handleCloseTxModal()}
        txHash={this.state.txHash}
        txStatus={this.state.txStatus}
        isMobile={this.props.isMobile}
      />
      <TxHandlingModal
        show={this.state.showTxHandlingModal}
        onHide={() => this.handleCloseTxHandlingModal()}
        account={this.props.account}
        txPrice={this.state.txPrice}
        accountEthBalance={this.state.accountEthBalance}
        sendCustomTeamTx={this.sendCustomTeamTx}
        minReasonableTxFee={this.state.minReasonableTxFee}
        maxAffordableFee={this.state.maxAffordableFee}
        maxAffordableTxFee={this.state.maxAffordableTxFee}
        isMobile={this.props.isMobile}
      />
      <NameChangeModal
        show={this.state.showNameChangeModal}
        onHide={() => this.setNameChangeModal(false)}
        setTeamName={this.props.setTeamName}
        CBAddress={this.props.CBAddressL2}
        isMobile={this.props.isMobile}
      />
      </>
    )};
}

export default Dashboard;

