import {React, useState, useCallback} from "react";
import Web3 from 'web3';
// Components
import {Spinner} from 'react-bootstrap';
import DownloadWalletModal from './modal/DownloadWalletModal';
// login 
import * as login from '../helpers/login';
import { log } from "@web3auth/base";


function SignInButton({loginButtonFallback,customText,isMobile}) {
    const [signer, setSigner] = useState(undefined)
    const [loginLoading, setLoginLoading] = useState(false)
    const [openDownloadMetaMask, setOpenDownloadMetaMask] = useState(false)

    async function signIn() {
        setLoginLoading(true);
    
        let loginMethod;
        let signer_;
        let web3;
    
        // Attempt WEB AUTH Sign In first
        try {
            const webAuthResult = await webAuthSignIn();
            signer_ = webAuthResult.signer_;
            web3 = webAuthResult.web3;
            loginMethod = "WEB3AUTH";
        } catch (e) {
            console.log("WEB AUTH Sign In failed:", e);
            // If WEB AUTH Sign In fails, attempt WEB3 Sign In
            try {
                const web3Result = await web3SignIn();
                signer_ = web3Result.signer_;
                web3 = web3Result.web3;
                loginMethod = "WEB3";
                if (web3 === undefined) {
                    setLoginLoading(false);
                    setOpenDownloadMetaMask(true);
                    return;
                }
            } catch (e) {
                console.log("WEB3 Sign In failed:", e);
                setLoginLoading(false);
                setOpenDownloadMetaMask(true);
                return;
            }
        }
    
        // STATE UPDATE
        setSigner(signer_);
        setLoginLoading(false);
        await login.accountChangeListener(accountListenerFallback);
        
        // Fallback to main page
        let loginData = prepLoginData(signer_, undefined, [], [], loginMethod, web3);
        loginButtonFallback(loginData);
    }
    
    // Sub-function for WEB AUTH Sign In
    async function webAuthSignIn() {
        let safeAuthKit = await login.initAuthL2();
        let signInResponse = await safeAuthKit.signIn();
        let signer_ = signInResponse.eoa;
        const web3 = new Web3(safeAuthKit.getProvider());
    
        return { signer_, web3 };
    }
    
    // Sub-function for WEB3 Sign In
    async function web3SignIn() {
        let accounts = await login.connectWeb3Wallet();
        if (!accounts || accounts.length === 0) {
            throw new Error("No accounts found during WEB3 Sign In.");
        }
        let signer_ = accounts[0];
        let web3 = await login.loadWeb3Provider();
    
        return { signer_, web3 };
    }
    

    const accountListenerFallback = useCallback(async (callbackData) => {
        let loginData = prepLoginData(callbackData.signer,undefined,[],[],undefined);
        loginButtonFallback(loginData)
    }, []);

    return (
        <div style={{width: "100%"}}>
            <button className="gradient-button" style={{color: "black"}} onClick={() => signIn()}>
            {!loginLoading ? (
                <div
                  className="sub-title-mobile"
                > 
                  {customText} 
                </div>
            ):(
                <Spinner animation="border" variant="dark" />
            )}
            </button>
            <DownloadWalletModal
                show={openDownloadMetaMask}
                onHide={() => setOpenDownloadMetaMask(false)}
            />
        </div>
    );


    function prepLoginData(signer,selectedSafe,ownedSafes,ownedSafesData, loginMethod,web3){
        // console.log(signer,selectedSafe,ownedSafes,ownedSafesData,web3)
        let loginData = {}
        loginData.signer = signer;
        loginData.selectedSafe = selectedSafe;
        loginData.ownedSafes = ownedSafes;
        loginData.ownedSafesData = ownedSafesData;
        loginData.loginMethod = loginMethod;
        loginData.web3 = web3;
        return loginData;
    }

}

    export default SignInButton;